export const HOME = "HOME";
export const MANAGE_ASSOCIATES = "USER";
export const MANAGE_PROJECTS = "PROJECT";
export const MANAGE_COMPANYS = "COMPANY";
export const MANAGE_TASKS = "TASK";
export const MANAGE_TIMESHEET = "TIMESHEET";
export const MANAGE_HOLIDAYS = "HOLIDAY";
export const APPROVALS = "Approvals";
export const REPORT = "REPORT";
export const ANALYTICS = "ANALYTICS";
export const RATINGS = "RATINGS";
export const WEEKLYSPRINTREPORT = "WEEKLYSPRINTREPORT";
export const RELEASENOTES = "RELEASENOTES";
export const FEEDBACK = "FEEDBACK";
export const QR = "QR";

export const ROLE_USER = "User";
export const ROLE_ADMIN = "Admin";
export const ROLE_PROJECT_MANAGER = "Project Manager";
export const ROLE_REPORTING_MANAGER = "Reporting Manager";

export const ROLES = [
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_PROJECT_MANAGER,
    ROLE_REPORTING_MANAGER
];

export const ROLE_BASED_RESOURCES = {
    [ROLE_USER]: [HOME, MANAGE_TASKS, MANAGE_TIMESHEET, RATINGS,RELEASENOTES,QR],
    [ROLE_ADMIN]: [HOME, MANAGE_ASSOCIATES, MANAGE_PROJECTS, MANAGE_COMPANYS, MANAGE_HOLIDAYS, APPROVALS, REPORT, RATINGS, RELEASENOTES,FEEDBACK],
    [ROLE_PROJECT_MANAGER]: [HOME, MANAGE_ASSOCIATES, REPORT, MANAGE_TASKS, MANAGE_TIMESHEET, RATINGS, RELEASENOTES,QR],
    [ROLE_REPORTING_MANAGER]: [HOME, MANAGE_ASSOCIATES, MANAGE_TASKS, MANAGE_TIMESHEET, RATINGS, RELEASENOTES,QR],
    
};