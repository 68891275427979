export const LIKE = "Like";
export const DISLIKE = "Dislike";
export const LOVED_IT = "Loved It";

export const USERS = "Users";
export const USER_DETAILS = "Users Details";
export const PROJECTS = "Projects";
export const PROJECT_DETAILS = "Project Details";
export const HOLIDAYS = "Holidays";
export const COMPANY = "Company";
export const COMPANY_DETAILS = "Company Details";
export const TIMESHEET = "Timesheet";
export const USER_RATINGS = "User Ratings";
export const RELEASE_NOTES = "Release Notes";
export const PERF_SHEET = "Perf Sheet";
export const USER_HOME = "User Home";
export const ADMIN_HOME = "Admin Home";
export const TASKS = "Tasks";
