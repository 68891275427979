import { Description, Event, HourglassTopSharp, Note, PriorityHigh, Person } from "@mui/icons-material";
import CategoryIcon from '@mui/icons-material/Category';
import { InputAdornment } from "@mui/material";
import { TASK_CATEGORY_FIELD_ID, TASK_DESCRIPTION_FIELD_ID, TASK_END_DATE_FIELD_ID, TASK_ESTIMATEDHOURS_FIELD_ID, TASK_PROJECTS_FIELD_ID, TASK_START_DATE_FIELD_ID, TASK_TITLE_FIELD_ID, TIMESHEET_TASKS_FIELD_ID, TASK_ASSIGNEE_FIELD_ID } from "../constants/Constants";
import moment from "moment";
 
export const taskFields = {
    [TASK_TITLE_FIELD_ID]: {
        id: TASK_TITLE_FIELD_ID,
        name: TASK_TITLE_FIELD_ID,
        type: "text",
        label: "Task Name",
        placeholder: "Enter Task Name",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <Note fontSize="small" />
                </InputAdornment>
            )
        }
    },
 
    [TASK_ESTIMATEDHOURS_FIELD_ID]: {
        id: TASK_ESTIMATEDHOURS_FIELD_ID,
        name: TASK_ESTIMATEDHOURS_FIELD_ID,
        type: "text",
        label: "hh:mm",
        placeholder: "00:00",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <HourglassTopSharp fontSize="small" />
                </InputAdornment>
            )
        }
    },
 
    [TASK_DESCRIPTION_FIELD_ID]: {
        id: TASK_DESCRIPTION_FIELD_ID,
        name: TASK_DESCRIPTION_FIELD_ID,
        type: "text",
        multiline: true,
        rows: 4,
        label: "Task Description",
        placeholder: "Enter Description",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <Description fontSize="small" />
                </InputAdornment>
            )
        }
    },
 
    [TASK_PROJECTS_FIELD_ID]: {
        id: TASK_PROJECTS_FIELD_ID,
        name: TASK_PROJECTS_FIELD_ID,
        select: true,
        label: "Projects",
        placeholder: "Enter Projects",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <PriorityHigh fontSize="small" />
                </InputAdornment>
            )
        }
    },
 
    [TASK_CATEGORY_FIELD_ID]: {
        id: TASK_CATEGORY_FIELD_ID,
        name: TASK_CATEGORY_FIELD_ID,
        select: true,
        label: "Category",
        placeholder: "Enter Categories",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <CategoryIcon fontSize="small" />
                </InputAdornment>
            )
        }
    },
 
    [TASK_START_DATE_FIELD_ID]: {
        id: TASK_START_DATE_FIELD_ID,
        name: TASK_START_DATE_FIELD_ID,
        type: "date",
        label: "Start Date",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        inputProps: { max: moment(new Date()).endOf("month").format("YYYY-MM-DD") }
    },
 
    [TIMESHEET_TASKS_FIELD_ID]: {
        id: TIMESHEET_TASKS_FIELD_ID,
        name: TIMESHEET_TASKS_FIELD_ID,
        select: true,
        label: "Select Tasks",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        }
    },
 
    [TASK_END_DATE_FIELD_ID]: {
        id: TASK_END_DATE_FIELD_ID,
        name: TASK_END_DATE_FIELD_ID,
        type: "date",
        label: "End Date",
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        inputProps: { max: moment(new Date()).endOf("month").format("YYYY-MM-DD") }
    },
 
    [TASK_ASSIGNEE_FIELD_ID]: {
        id: TASK_ASSIGNEE_FIELD_ID,
        name: TASK_ASSIGNEE_FIELD_ID,
        select: true,
        label: "Assigned To",
        placeholder: "Select Assignee",
        variant: "outlined",
       // required: true,
        InputLabelProps: { shrink: true },
        InputProps: {
            startAdornment: (
                <InputAdornment position="start">
                    <Person fontSize="small" />
                </InputAdornment>
            )
        }
    }
};