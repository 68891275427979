export const perfParameters = [
  {
    slNo: 1,
    index: 0,
    Objective: "Capability",
    subIndex: 0,
    weightage:30,
    title: "Execution Efficiency",
    desc: "Timeliness, resource optimization, utilization of tools/technology for better outcomes, adaptability and communication (Clarity, consistency in updates, presentation, and quality of documentation.",
    keyfocusarea:"Meeting project timelines without delays\nEffective prioritization of tasks to ensure on-time delivery\nOptimal use of resources without wastage\nAbility to seek and leverage support when needed\nAbility to adapt and find solutions under pressure\nProactive identification and mitigation of potential risks"
  },
  {
    slNo: 2,
    index: 0,
    Objective: "Capability",
    subIndex: 1,
    weightage:30,
    title: "Quality of Output",
    desc: "Adherence to specifications, design standards, accuracy, and completeness of deliverables.",
  },
  {
    slNo: 3,
    index: 0,
    Objective: "Capability",
    subIndex: 2,
    weightage:40,
    title: "Accountability and Ownership",
    desc: "Taking responsibility, proactive decision-making, and ensuring task completion.",
  },

  {
    slNo: 4,
    index: 1,
    Objective: "Creativity",
    subIndex: 0,
    weightage:20,
    title: "Originality",
    desc: " Measures the uniqueness and innovation in ideas, solutions, or approaches.",
  },
  {
    slNo: 5,
    index: 1,
    Objective: "Creativity",
    subIndex: 1,
    weightage:60,
    title: "Alignment",
    desc: "Evaluates how well actions and outcomes are in sync with the goals of the project, client requirements, organizational vision and the potential for the solution to grow, adapt, or create meaningful change across a broader context.",
  },
  {
    slNo: 6,
    index: 1,
    Objective: "Creativity",
    subIndex: 2,
    weightage:20,
    title: "Complexity and Effort",
    desc: "Tracks the level of difficulty and resources required to achieve a result.",
  },

  {
    slNo: 7,
    index: 2,
    Objective: "Collaboration",
    subIndex: 0,
    weightage:40,
    title: "Team Contribution",
    desc: "Measures active involvement and willingness to assist team members in achieving shared goals.",
  },
  {
    slNo: 8,
    index: 2,
    Objective: "Collaboration",
    subIndex: 1,
    weightage:20,
    title: "Adaptability & Flexibility",
    desc: "Evaluates the readiness to adjust to changing team dynamics, roles, or priorities.",
  },
  {
    slNo: 9,
    index: 2,
    Objective: "Collaboration",
    subIndex: 2,
    weightage:40,
    title: "Relationship Building",
    desc: "Tracks the ability to foster trust, mutual respect, open communication within the team and conflict resolution (the ability to address and resolve disagreements constructively..)",
  },
  {
    slNo: 10,
    index: 3,
    Objective: "Compliance",
    subIndex: 0,
    weightage:30,
    title: "Awareness",
    desc: "Evaluates understanding of organizational policies and procedures and the ability to promote compliance among peers.",
  },
  {
    slNo: 11,
    index: 3,
    Objective: "Compliance",
    subIndex: 1,
    weightage:30,
    title: "Adherence",
    desc: "Measures consistency in following established rules, processes, timelines and the ability to resolve compliance-related issues by identifying and addressing them promptly",
  },
  {
    slNo: 12,
    index: 3,
    Objective: "Compliance",
    subIndex: 2,
    weightage:40,
    title: "Accuracy and Integrity of Records",
    desc: "Tracks the precision and honesty in maintaining compliance-related documentation and records.",
  },
  {
    slNo: 13,
    index: 4,
    Objective: "Customer",
    subIndex: 0,
    weightage:30,
    title: "Convenience",
    desc: "Deep commitment to understanding customer requirements and delivering with ease and efficiency to exceed their expectations.",
  },
  {
    slNo: 14,
    index: 4,
    Objective: "Customer",
    subIndex: 1,
    weightage:20,
    title: "Value Creation",
    desc: "Emphasizes providing increasingly customer-friendly options and solutions that enhance the customer experience, ensuring they continue to stay with us while contributing to business growth.",
  },
  {
    slNo: 15,
    index: 4,
    Objective: "Customer",
    subIndex: 2,
    weightage:10,
    title: "Cost Optimization",
    desc: "Promotes operational efficiency and cost management to benefit both the company and its customers.",
  },
  {
    slNo: 16,
    index: 4,
    Objective: "Customer",
    subIndex: 3,
    weightage:40,
    title: "Brand Promotion",
    desc: "Aims to enhance the company’s market perception and reputation through consistent and positive customer experiences.",
  },
];
