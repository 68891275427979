import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import EmployeeFeedbackForm from "./EmployeeFeedbackForm";
import FeatureReviews from "./FeatureReviews";
import QRCodeGenerator from "./QRCodeGenerator";
import FeedbackDashboard from "./FeedBackDashBoard";

export const REVIEW_ROUTES = [
  <Route
    path="/home/reviews"
    element={
      <PrivateRoute>
        <Layout
          component={FeatureReviews}
          path="/home/reviews"
          title="Reviews"
        ></Layout>
      </PrivateRoute>
    }
    key={"HOME_ROUTE"}
  />,
  <Route
    path="/feedback-form/:id"
    element={<EmployeeFeedbackForm />}
    key={"HOME_ROUTE"}
  />,
  <Route
    path="/home/share"
    element={
      <PrivateRoute>
        <Layout
          component={FeedbackDashboard}
          path="/home/share"
          title="QR Code"
        ></Layout>
      </PrivateRoute>
    }
  
  />,
];
