import {
  AutoAwesome,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ThumbDown,
  ThumbDownAltOutlined,
  ThumbUpOffAltOutlined,
  ThumbUpSharp,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Switch,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState, version } from "react";
import { DISLIKE, LIKE, LOVED_IT } from "../constant";
import moment from "moment";
import { motion } from "framer-motion";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";

const reactions = [
  {
    title: LIKE,
    icon: ThumbUpOffAltOutlined,
    submitIcon: ThumbUpSharp,
    color: "teal",
  },
  {
    title: DISLIKE,
    icon: ThumbDownAltOutlined,
    submitIcon: ThumbDown,
    color: "red",
  },
  {
    title: LOVED_IT,
    icon: FavoriteBorderOutlined,
    submitIcon: FavoriteOutlined,
    color: "purple",
  },
];

const Reaction = ({ type, user }) => {
  const isAdmin = user?.roles[0] === "Admin";
  const [reaction, setReaction] = useState(null);
  const [comment, setComment] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [reactionCount, setReactionCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [anonymous, setAnonymous] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [showReactions, setShowReactions] = useState(false);

  const handleOnClickReaction = (reactionType) => {
    setReaction(reactionType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setComment("");
    setAnonymous(false);
    setOpenDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedTimestamp = moment().format("YYYY-MM-DD HH:mm:ss");

    const data = {
      associateId: user?.associateId,
      feature: type,
      reaction,
      comment,
      anonymous,
      version: currentVersion?.version,
      feedbackTimestamp: formattedTimestamp,
    };
    axiosInstance
      .post("/feedback/submit", data)
      .then(() => {
        setFeedback(data);
        setComment("");
        getCount();
        handleCloseDialog();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const pastReaction = reactions.find((r) => r.title === feedback?.reaction);

  const getCount = () => {
    if (isAdmin) {
      axiosInstance
        .get(`/feedback/reaction-count?feature=${type}`)
        .then((res) => setReactionCount(res?.data))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (currentVersion === null) {
      const fetchNotes = async () => {
        try {
          const response = await fetch("/releaseNotes.json");
          const notes = await response.json();
          setCurrentVersion(notes?.[0] ?? null);
        } catch (error) {
          console.error("Error fetching release notes:", error);
        }
      };

      fetchNotes();
    }
    if (type) {
      getCount();
      setComment("");
      axiosInstance
        .get(`/feedback/associate/${user?.associateId}/feature/${type}`)
        .then((res) => {
          setFeedback(res?.data);
          setShowReactions(true);
        })
        .catch(() => {
          setFeedback({});
          setShowReactions(true);
        });
    }
  }, [type]);

  const enhanceWithBunai = async (comment, context) => {
    if (!comment.trim()) return;
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "https://bunai.edvenswatech.com/service/enhancetext",
        {
          input_text: comment,
          context,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data?.response) {
        setComment(response.data?.response);
      }
    } catch (error) {
      console.error("Error enhancing text:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack direction="row">
      {(currentVersion &&
        currentVersion?.version !== feedback?.version &&
        currentVersion?.features.includes(type)) ||
      Object.keys(feedback).length === 0
        ? showReactions &&
          reactions.map(({ title, icon: Icon, color }) => (
            <Tooltip key={title} title={title}>
              <Badge badgeContent={reactionCount?.[title] ?? 0} color="info">
                <IconButton onClick={() => handleOnClickReaction(title)}>
                  <Icon sx={{ fontSize: 18, color }} />
                </IconButton>
              </Badge>
            </Tooltip>
          ))
        : showReactions && (
            <Tooltip key={pastReaction?.title} title={pastReaction?.title}>
              <Badge
                badgeContent={
                  isAdmin ? reactionCount?.[pastReaction?.title] ?? 0 : null
                }
                color="info"
              >
                <IconButton disabled>
                  {pastReaction &&
                    React.createElement(pastReaction.submitIcon, {
                      sx: { fontSize: 18, color: pastReaction.color },
                    })}
                </IconButton>
              </Badge>
            </Tooltip>
          )}

      <Dialog
        fullWidth
        maxWidth="md"
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "white",
            background: "linear-gradient(135deg, #008080, #004d4d)",
            p: 2,
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          We Value Your Feedback
        </DialogTitle>
        <DialogContent sx={{ p: 3, bgcolor: "#f9f9f9", borderRadius: 2 }}>
          <Box
            sx={{
              bgcolor: "white",
              p: 3,
              borderRadius: 2,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              <strong>Feature:</strong> {type}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Selected Reaction:</strong> {reaction}
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  checked={anonymous}
                  onChange={(e) => setAnonymous(e.target.checked)}
                  sx={{
                    "& .MuiSwitch-thumb": { bgcolor: "white" },
                    "& .MuiSwitch-track": { bgcolor: "#008080" },
                  }}
                />
              }
              label="Submit as Anonymous"
              sx={{ mt: 1, fontWeight: "bold", color: "#333" }}
            />

            <Grid
              container
              mt={2}
              component="form"
              onSubmit={handleSubmit}
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Every comment counts! Help us improve."
                  sx={{
                    bgcolor: "white",
                    borderRadius: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#008080" },
                      "&:hover fieldset": { borderColor: "#005959" },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={loading ? "Enhancing..." : "Enhance"}>
                          <IconButton
                            size="small"
                            onClick={() => enhanceWithBunai(comment, "")}
                            sx={{
                              color: "white",
                              backgroundColor: "teal",
                              borderRadius: "50%",
                              width: 36,
                              height: 36,
                              "&:hover": { backgroundColor: "#005959" },
                            }}
                          >
                            <motion.div
                              animate={loading ? { rotate: 360 } : {}}
                              transition={
                                loading
                                  ? {
                                      repeat: Infinity,
                                      duration: 1,
                                      ease: "linear",
                                    }
                                  : {}
                              }
                            >
                              <AutoAwesome />
                            </motion.div>
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="right">
                <Button size="small" variant="contained" type="submit">
                  Submit
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleCloseDialog}
                  sx={{ ml: 1 }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default Reaction;
