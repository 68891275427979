import {
  ArrowForwardOutlined,
  ChangeCircle,
  Close,
  People,
  SearchOutlined,
} from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Alert,
  Avatar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuList,
  Snackbar,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EdvLogo from "../../assets/edvenswa_logo_optimized_50.png";
import AuthLogo from "../../assets/5Clogo.png";
import { axiosInstance } from "../../interceptors/AxiosInterceptor";
import { doLogout } from "../edvenswa.emportal.auth/actions/Action";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../edvenswa.emportal.auth/constants/Constants";
import BasicBreadcrumbs from "../edvenswa.emportal.common/breadcrumbs/BasicBreadcrumbs";
import PastRatingsCustomCell from "../edvenswa.emportal.reports/pages/PastRatingsCustomCell";
import { getResources } from "../edvenswa.emportal.sidebar/factory/ResourceFactory";
import { routes } from "./Constants";
import Reaction from "../edvenswa.emportal.common/pages/Reaction";
import { FEEDBACK } from "../edvenswa.emportal.sidebar/constants/constants";

Layout.propTypes = {
  component: PropTypes.elementType.isRequired,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const LOGOUT_ACTION = "LOGOUT";
const SETTTINGS_MENU_ITEMS = [
  {
    label: "Profile",
    navigateTo: "/home/profile",
    icon: <People />,
  },
  {
    label: "Change Password",
    navigateTo: "/home/changepassword",
    icon: <ChangeCircle />,
  },
  {
    label: "Logout",
    action: LOGOUT_ACTION,
    icon: <Logout />,
  },
];

export default function Layout(props) {
  const user = JSON.parse(
    sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
  );
  const { title, path } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [severity, setSeverity] = useState("error");
  const [state, setState] = useState({});
  useEffect(() => {
    if (user.roles[0] === "Admin") {
      if (localStorage.getItem("companies") === null) {
        axiosInstance
          .get("/tenant/getAll")
          .then((res) => {
            const activeCompanies = res.data.filter(
              (data) => data.status === "ACTIVE"
            );
            localStorage.setItem("companies", JSON.stringify(activeCompanies));
          })
          .catch((err) => console.log(err));
      }
    }
    // if (user.roles[0] === "Project Manager") {
    //   if (localStorage.getItem("projects") === null) {
    //     axiosInstance.get("/user/findByprojectManager").then(res => {
    //       localStorage.setItem("projects", JSON.stringify(res.data))
    //     })
    //       .catch(err => console.log(err))
    //   }
    // }
  }, []);

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const [load, setLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [localRoutes, setLocalRoutes] = useState([]);
  const [users, setUsers] = useState([]);
  const MainComponent = props.component;
  const Companies = JSON.parse(localStorage.getItem("companies"));
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [sidebarItems, setSidebarItems] = useState([]);
  const [closingMenu, setClosingMenu] = useState(false);
  const searchBoxRef = useRef(null);
  const currentPath = window.location.hash.slice(
    window.location.hash.indexOf("#") + 1
  );
  const [ratings, setRatings] = useState({});
  const [type, setType] = useState(null);

  useEffect(() => {
    if (Object.keys(ratings).length === 0 && user?.roles[0] !== "Admin") {
      const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero
        const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
        return `${year}-${month}-${day}`;
      };
      axiosInstance
        .put(`/user/get/past/ratings?endDate=${getCurrentDate()}`, [
          user?.associateId,
        ])
        .then((res) => setRatings(res?.data))
        .catch((err) => console.log(err));
    }

    if (sidebarItems.length === 0) {
      setSidebarItems(getResources(user?.roles));
    }
    if (user.logo && user.logo !== "null") {
      setImage(user?.logo);
    }
  }, [user, sidebarItems, image, user]);

  useEffect(() => {
    if (closingMenu && !Boolean(anchorEl)) {
      setClosingMenu(false);
    }
  }, [anchorEl, closingMenu]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };
  const handleLogoutClick = () => {
    doLogout();
    navigate("/");
  };

  const handleNavigation = (action, navigateTo) => {
    setAnchorEl(null);
    if (action === LOGOUT_ACTION) {
      handleLogoutClick();
      return;
    }
    navigate(navigateTo);
    setClosingMenu(true);
  };

  const handleError = (error, severity = "error") => {
    setSnackbarOpen(!snackbarOpen);
    setSeverity(severity);
    if (error?.response && error.response?.data) {
      // application specific error
      setMessage(error?.response?.data);
    } else {
      // generic axios error
      setMessage(error.message);
    }
  };

  const handleSuccess = (message, severity = "success") => {
    setSnackbarOpen(!snackbarOpen);
    setSeverity(severity);
    setMessage(message);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm.length < 3) {
        setUsers([]);
        setLocalRoutes([]);
        return;
      }
      try {
        setUsers([]);
        const { data } = await axiosInstance.post(
          `user/find?searchTerm=${encodeURIComponent(searchTerm)}`
        );
        if (data && data.length > 0) {
          setUsers(data);
          setLocalRoutes([]);
        }
        const userRole = user.roles[0];
        let filteredRoutes = routes;

        // Exclude routes based on the user's role
        if (userRole === "Project Manager") {
          const excludedIds = [
            "Projects",
            "Company",
            "Holidays",
            "Add Projects",
            "Add Holidays",
            "Add Company",
          ];
          filteredRoutes = filteredRoutes.filter(
            (route) => !excludedIds.includes(route.id)
          );
        }
        if (userRole === "Reporting Manager") {
          const excludedIds = [
            "Projects",
            "Company",
            "Holidays",
            "Add Projects",
            "Add Holidays",
            "Add Company",
          ];
          filteredRoutes = filteredRoutes.filter(
            (route) => !excludedIds.includes(route.id)
          );
        }

        // Filter routes based on the search term
        const matchingRoutes = filteredRoutes.filter((route) =>
          route.id.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setLocalRoutes(matchingRoutes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTerm]);

  useEffect(() => {
    const handleFocusOut = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.relatedTarget)
      ) {
        setSearchTerm("");
        setUsers([]);
        setLocalRoutes([]);
      }
    };

    // Attach focusout event on the container
    const searchBoxElement = searchBoxRef.current;
    if (searchBoxElement) {
      searchBoxElement.addEventListener("focusout", handleFocusOut);
    }

    return () => {
      // Clean up the event listener
      if (searchBoxElement) {
        searchBoxElement.removeEventListener("focusout", handleFocusOut);
      }
    };
  }, []);
  const handleListItemClick = (path, state) => {
    navigate(path, { state });
    setSearchTerm("");
    setLocalRoutes([]);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            open={open}
            sx={{
              backgroundColor: "#FFFFFF",
              borderBottom: "0.5px solid #2998FF",
            }}
          >
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <Grid container>
                {/* First Grid Item */}
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    color="#2381D9"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      marginRight: 5,
                      ...(open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>

                  <Grid item xs={2}>
                    <img
                      src={EdvLogo}
                      alt={AuthLogo}
                      width={"90%"}
                      loading="lazy"
                    />
                  </Grid>

                  {user.roles[0] !== "User" && (
                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        position: "relative",
                      }}
                      ref={searchBoxRef}
                    >
                      <TextField
                        variant="outlined"
                        placeholder="Search by Username, Projects, Reports etc."
                        size="small"
                        value={searchTerm}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchOutlined />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Close
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                                sx={{ color: "#1C243C" }}
                                onClick={() => {
                                  setSearchTerm("");
                                  setUsers([]);
                                  setLocalRoutes([]);
                                }}
                              />
                            </InputAdornment>
                          ),
                          sx: {
                            "& input::placeholder": {
                              fontSize: "0.75rem",
                            },
                          },
                        }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "teal",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "teal",
                            },
                          },
                        }}
                      />
                      {users.length > 0 || localRoutes.length > 0 ? (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            zIndex: 1,
                            width: "100%",
                            maxWidth: "350px",
                            bgcolor: "#E5F1ED",
                            marginTop: 0.5,
                            maxHeight: "300px",
                            overflowY: "auto",
                          }}
                        >
                          <List>
                            {users.map((element, key) => (
                              <ListItemButton
                                key={key}
                                sx={{ padding: "4px 8px", minHeight: "32px" }}
                                onClick={() =>
                                  handleListItemClick("/home/users/details", {
                                    user: element,
                                  })
                                }
                              >
                                <ListItemIcon>
                                  <Avatar
                                    alt={element?.username}
                                    src={element?.logo}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography style={{ color: "black" }}>
                                      {`${element.firstname} ${element.lastname}`}
                                    </Typography>
                                  }
                                  secondary={element?.username}
                                />
                              </ListItemButton>
                            ))}
                            {localRoutes.map((element, key) => (
                              <ListItemButton
                                key={key}
                                onClick={() =>
                                  handleListItemClick(
                                    element.path,
                                    element.state
                                  )
                                }
                                sx={{ padding: "2px 4px", minHeight: "24px" }}
                              >
                                <ListItemText
                                  primary={
                                    <Stack direction="row" spacing={2}>
                                      <ArrowForwardOutlined
                                        style={{ color: "black" }}
                                      />
                                      <Box>
                                        <Typography style={{ color: "black" }}>
                                          {element.id}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          {element?.text}
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  }
                                />
                              </ListItemButton>
                            ))}
                          </List>
                        </Box>
                      ) : searchTerm.length > 2 ? (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            zIndex: 1,
                            width: "100%",
                            maxWidth: "290px",
                            bgcolor: "#ffffff",
                            marginTop: 0.5,
                            padding: "16px",
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="body1" color="textSecondary">
                            No results found
                          </Typography>
                        </Box>
                      ) : null}
                    </Grid>
                  )}
                </Grid>

                {/* Second Grid Item */}
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title={
                      <Typography style={{ textAlign: "left" }}>
                        <Typography color={"white"}>Capability</Typography>
                        <Typography color={"white"}>Creativity</Typography>
                        <Typography color={"white"}>Collaboration</Typography>
                        <Typography color={"white"}>Compliance</Typography>
                        <Typography color={"white"}>Customer Focus</Typography>
                      </Typography>
                    }
                    placement="top"
                    arrow
                  >
                    <img
                      src={AuthLogo}
                      alt="Auth Logo"
                      width="65px"
                      height="65px"
                      loading="lazy"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Grid>

                {/* Third Grid Item */}
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {user?.roles[0] !== "Admin" &&
                    Object.keys(ratings).length > 0 &&
                    ratings?.[user?.associateId]?.["finalRating"] &&
                    ratings?.[user?.associateId] && (
                      <>
                        <Typography
                          fontFamily="Lucida grande"
                          style={{ color: "#016466", marginRight: "10px" }}
                          sx={{ fontSize: "16px" }}
                        >
                          UK Score{" "}
                          {Math.floor(
                            ratings?.[user?.associateId]?.["finalRating"] * 180
                          )}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            marginRight: "10px",
                          }}
                        >
                          <PastRatingsCustomCell
                            user={user}
                            ratings={ratings?.[user?.associateId]}
                            type="header"
                            astId={user?.associateId}
                          />
                        </Box>
                      </>
                    )}

                  <Typography
                    fontFamily="Lucida grande"
                    style={{ color: "#016466" }}
                    sx={{ fontSize: "16px" }}
                  >
                    {user?.firstname} {user?.lastname}
                  </Typography>
                  <IconButton
                    sx={{
                      display: "flex",
                      alignItems: "right",
                      color: "#016466",
                    }}
                    onClick={handleOpenUserMenu}
                  >
                    <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                  </IconButton>

                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open Settings">
                      <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                        <Avatar
                          imgProps={{ loading: "lazy" }}
                          src={
                            image
                              ? image
                              : require("../../assets/emportal_default_user.png")
                          }
                          alt={user?.username}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuList>
                      {SETTTINGS_MENU_ITEMS.map((SETTING_MENU_ITEM, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            handleNavigation(
                              SETTING_MENU_ITEM.action,
                              SETTING_MENU_ITEM.navigateTo
                            );
                          }}
                        >
                          <ListItemIcon>{SETTING_MENU_ITEM.icon}</ListItemIcon>
                          <ListItemText>{SETTING_MENU_ITEM.label}</ListItemText>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Grid>
              </Grid>
            </Toolbar>

            {load ? <LinearProgress /> : <></>}
          </AppBar>

          <Drawer
            variant="permanent"
            hideBackdrop
            open={open}
            sx={{ backgroundColor: "#FFFFFF" }}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider sx={{ borderRight: "0.5px solid #2998FF" }} />
            <List>
              {sidebarItems.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => navigate(item.path)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>

          <Box component="main" sx={{ flexGrow: 1, marginTop: "50px", p: 2 }}>
            <Stack
              direction="row"
              sx={{
                mt: "6px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {title && path ? (
                <BasicBreadcrumbs
                  title={title}
                  path={path}
                  currentPath={currentPath}
                />
              ) : (
                <Grid></Grid>
              )}

              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography style={{fontSize:'12px'}}>
                  Powered By{" "}
                  <Tooltip title="Visit BUNAI" arrow>
                    <a
                      href="https://bunai.edvenswatech.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "teal",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)", 
                        transition: "text-decoration 0.2s ease-in-out",
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.textDecoration = "underline")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.textDecoration = "none")
                      }
                    >
                      Edvenswa.AI
                    </a>
                  </Tooltip>
                </Typography>

                {type !== FEEDBACK && <Reaction type={title} user={user} />}
              </Stack>
            </Stack>

            <MainComponent
              {...props}
              state={state}
              setType={setType}
              setState={setState}
              Companies={Companies}
              title={title}
              path={path}
              user={user}
              projects={
                user.roles[0] === "Reporting Manager"
                  ? user?.reportingManagerprojects[0]?.project
                  : user?.projects
              }
              onError={handleError}
              onSuccess={handleSuccess}
              load={load}
              setLoad={setLoad}
            />
          </Box>
        </Box>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(!snackbarOpen)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
    </Grid>
  );
}
