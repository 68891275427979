import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import UserAppraisal from "../pages/UserAppraisal";
import ShowUsers from "../pages/ShowUsers";
import UserScreenAppraisal from "../pages/UserScreenAppraisal";

export const APPRAISAL_ROUTES = [
  <Route
    path="/home/ratings/UserAppraisalForm"
    element={
      <PrivateRoute>
        <Layout component={UserAppraisal} path="/home/ratings/UserAppraisalForm" title="PERF Form"></Layout>
      </PrivateRoute>
    }
    key={"TASK_ROUTE"}
  ></Route>,
  <Route
    path="/home/ratings"
    element={
      <PrivateRoute>
        <Layout component={ShowUsers} path="/home/ratings" title="Appraisals"></Layout>
      </PrivateRoute>
    }
    key={"TASK_ROUTE"}
  ></Route>,
  <Route
    path="/home/ratings/UserScreenAppraisal"
    element={
      <PrivateRoute>
        <Layout component={UserScreenAppraisal} path="/home/ratings/UserScreenAppraisal" title="User Appraisals"></Layout>
      </PrivateRoute>
    }
    key={"TASK_ROUTE"}
  ></Route>,
];
