import {
  Apartment,
  Assessment,
  Event,
  Home,
  Hub,
  NewReleases,
  PeopleAlt,
  QrCode,
  Reviews,
} from "@mui/icons-material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ApprovalIcon from "@mui/icons-material/Approval";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import ListAltIcon from "@mui/icons-material/ListAlt";
import StarsIcon from "@mui/icons-material/Stars";
import { Tooltip } from "@mui/material";
import React from "react";
import {
  ANALYTICS,
  APPROVALS,
  FEEDBACK,
  HOME,
  MANAGE_ASSOCIATES,
  MANAGE_COMPANYS,
  MANAGE_HOLIDAYS,
  MANAGE_PROJECTS,
  MANAGE_TASKS,
  MANAGE_TIMESHEET,
  QR,
  RATINGS,
  RELEASENOTES,
  REPORT,
  WEEKLYSPRINTREPORT,
} from "./constants/constants";

const SidebarItems = [
  {
    id: HOME,
    title: "Home",
    path: "/home",
    icon: (
      <Tooltip title="Home">
        <Home sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: MANAGE_ASSOCIATES,
    title: "Manage Associates",
    path: "/home/users",
    icon: (
      <Tooltip title="Manage Associates">
        <PeopleAlt sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: MANAGE_PROJECTS,
    title: "Manage Projects",
    path: "/home/projects",
    icon: (
      <Tooltip title="Manage Projects">
        <Hub sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: MANAGE_TASKS,
    title: "Manage Tasks",
    path: "/home/task",
    icon: (
      <Tooltip title="Manage Tasks">
        {" "}
        <ListAltIcon sx={{ fontSize: 30, color: "#029a9c" }} />{" "}
      </Tooltip>
    ),
  },
  {
    id: MANAGE_TIMESHEET,
    title: "Manage Timesheets",
    path: "/home/timecard/timesheet",
    icon: (
      <Tooltip title="Manage Timesheets">
        {" "}
        <AccessTimeFilledIcon sx={{ fontSize: 30, color: "#029a9c" }} />{" "}
      </Tooltip>
    ),
  },
  {
    id: MANAGE_HOLIDAYS,
    title: "Manage Holidays",
    path: "/home/holidays",
    icon: (
      <Tooltip title="Manage Holidays">
        <Event sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: MANAGE_COMPANYS,
    title: "Manage Company",
    path: "/home/company",
    icon: (
      <Tooltip title="Manage Company">
        <Apartment sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: APPROVALS,
    title: "Manage Approvals",
    path: "/home/timecard",
    icon: (
      <Tooltip title="Manage Approvals">
        <ApprovalIcon sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: REPORT,
    title: "Manage Reports",
    path: "/home/reports",
    icon: (
      <Tooltip title="Report">
        <AssignmentReturnedIcon sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: ANALYTICS,
    title: "Manage Analytics",
    path: "/home/analytics",
    icon: (
      <Tooltip title="Analytics">
        <AnalyticsIcon sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: RATINGS,
    title: "Ratings",
    path: "/home/ratings",
    icon: (
      <Tooltip title="Ratings">
        <StarsIcon sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: WEEKLYSPRINTREPORT,
    title: "Weekly SprintReport",
    path: "/home/WeeklySprint",
    icon: (
      <Tooltip title="Weekly SprintReprot">
        <Assessment sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: RELEASENOTES,
    title: "Release Notes",
    path: "/home/notes",
    icon: (
      <Tooltip title="Release Notes">
        <NewReleases sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  {
    id: FEEDBACK,
    title: "Feedback",
    path: "/home/reviews",
    icon: (
      <Tooltip title="Feedback">
        <Reviews sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
  ,
  {
    id: QR,
    title: "Feedback",
    path: `/home/share/`,
    icon: (
      <Tooltip title="Feedback">
        <QrCode sx={{ fontSize: 30, color: "#029a9c" }} />
      </Tooltip>
    ),
  },
];

export default SidebarItems;
