import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";
import PerformanceGraph from './PerformanceGraph';
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import {
    Box,

    Grid,
    TextField,
    Typography,

    Tooltip,
    IconButton,

    Select,
    MenuItem,
    Card,

    CircularProgress,
} from "@mui/material";
import { Margin } from '@mui/icons-material';
import ClassNameGenerator from '@mui/utils/ClassNameGenerator';

const PerfDashboard2 = (e) => {


    const [selectionKey, setSelectionKey] = useState('Capability'); // Default selection key
    const [selectedValue, setSelectedValue] = useState('ALL'); // Default to 'ALL' for all subcategories
    const [sortOrder, setSortOrder] = useState('desc'); // Default to descending sort order
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [perfData, setPerfData] = useState([]);
    const [loader, setLoader] = useState(false)
    const [sortedData, setSortedData] = useState([])


    const data = {
        Capability: [
            "Execution efficiency",
            "Quality of output",
            "Accountability and Ownership",
        ],
        Creativity: [
            "Originality",
            "Alignment",
            "Complexity and Effort"
        ],
        Collaboration: [
            "Team Contribution",
            "Adaptability and Flexibility",
            "Relationship Building"
        ],
        Compliance: [
            "Awareness",
            "Adherence",
            "Accuracy and Integrity of records"
        ],
        CustomerFocus: [
            "Convenience",
            "Value Creation",
            "Cost optimization",
            "Brand promotion"
        ]
    };


    const getBiweeklyRange = (date = new Date()) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        let start, end;
        if (day <= 15) {
            start = new Date(year, month, 1);
            end = new Date(year, month, 15);
        } else {
            start = new Date(year, month, 16);
            end = new Date(year, month, daysInMonth);
        }

        return {
            start: start.toISOString().split("T")[0], // Format as YYYY-MM-DD
            end: end.toISOString().split("T")[0],
        };
    };
    useEffect(() => {
        const { start, end } = getBiweeklyRange();
        setStartDate(start);
        setEndDate(end);
    }, []);




    // Handle biweekly navigation
    const handleStartDateChange = (e, type) => {
        if (type === "icon") {
            const newStartDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate)
                        .subtract(1, "month")
                        .startOf("month")
                        .add(15, "days")
                        .format("YYYY-MM-DD")
                    : moment(startDate).startOf("month").format("YYYY-MM-DD");
            const newEndDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate)
                        .subtract(1, "month")
                        .endOf("month")
                        .format("YYYY-MM-DD")
                    : moment(startDate)
                        .startOf("month")
                        .add(14, "day")
                        .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else if (type === "icon") {
            const newStartDate = moment(startDate)
                .subtract(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            const newEndDate = moment(endDate)
                .subtract(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else {
            const value = e.target.value;
            setStartDate(value);
            // setEndDateError("");
            if (value && endDate && value > endDate) {
                // setStartDateError("Start date cannot be after end date");
            } else {
                // setStartDateError("");
            }
        }
    };

    const handleEndDateChange = (e, type) => {
        if (type === "icon") {
            const newStartDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate).add(15, "days").format("YYYY-MM-DD")
                    : moment(startDate)
                        .add(1, "month")
                        .startOf("month")
                        .format("YYYY-MM-DD");
            const newEndDate =
                moment(startDate).format("DD").toString() === "01"
                    ? moment(startDate).endOf("month").format("YYYY-MM-DD")
                    : moment(startDate)
                        .add(1, "month")
                        .startOf("month")
                        .add(14, "days")
                        .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else if (type === "icon") {
            const newStartDate = moment(startDate)
                .add(1, "month")
                .startOf("month")
                .format("YYYY-MM-DD");
            const newEndDate = moment(endDate)
                .add(1, "month")
                .endOf("month")
                .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
        } else {
            const value = e.target.value;
            setEndDate(value);
            // setStartDateError("");
            if (value && startDate && value < startDate) {
                // setEndDateError("End date cannot be before start date");
            } else {
                // setEndDateError("");
            }
        }
    };


    const handleKeyChange = (event) => {
        setLoader(true)
        const selectedKey = event.target.value;
        setSelectionKey(selectedKey);
        setSelectedValue('ALL');
        setLoader(false)
    };

    const handleValueChange = (event) => {
        setLoader(true)
        const value = event.target.value;
        setSelectedValue(value);
        setLoader(false)
    };

    function parseRatings(data) {
        return data.map(item => ({
            ...item,
            ratingsObj: JSON.parse(item.ratingsObj)
        }));
    }

    function addUKKey(data) {
        return data.map((item, rowIndex) => {
            let factor = 0.3;


            if (rowIndex === 0) {
                const subIndexFactors = { 0: 0.3, 1: 0.3, 2: 0.4 };
                factor = subIndexFactors[item.subIndex] || 0.3;
            } else if (rowIndex === 1) {
                const subIndexFactors = { 0: 0.2, 1: 0.6, 2: 0.2 };
                factor = subIndexFactors[item.subIndex] || 0.3;
            } else if (rowIndex === 2) {
                const subIndexFactors = { 0: 0.4, 1: 0.2, 2: 0.4 };
                factor = subIndexFactors[item.subIndex] || 0.3;
            } else if (rowIndex === 3) {
                const subIndexFactors = { 0: 0.3, 1: 0.3, 2: 0.4 };
                factor = subIndexFactors[item.subIndex] || 0.3;
            } else if (rowIndex === 4) {
                const subIndexFactors = { 0: 0.3, 1: 0.2, 2: 0.1, 3: 0.4 };
                factor = subIndexFactors[item.subIndex] || 0.3;
            }


            return {
                ...item,
                UK: (item.rating * factor * 180) / 5
            };
        });
    }


    // const generateData = () => {
    //     setLoader(true)
    //     if (perfData.length !== 0) {
    //         let mainData = perfData.map((item) => {
    //             return {
    //                 ...item,
    //                 ratingsObj: addUKKey(item.ratingsObj)
    //             };
    //         });
    //         UkscoreData(mainData)
    //     } else {
    //         console.log("empty")
    //         setLoader(false)
    //     }
    // }

    const generateData = () => {
        setLoader(true);
        if (perfData.length !== 0) {
            let mainData = perfData
                .filter(item => item.projectManagerSubmission === true)
                .map((item) => {
                    return {
                        ...item,
                        ratingsObj: addUKKey(item.ratingsObj)
                    };
                });

            if (mainData.length > 0) {
                UkscoreData(mainData);
            } else {
                console.log("No data with project manager submission");
            }
        } else {
            console.log("empty");
            setLoader(false);
        }
    };




    const UkscoreData = (data) => {
        const updatedData = data.map((x) => {
            let finalUKSCORE = 0;

            if (selectionKey === "Capability" && selectedValue === "ALL") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 0) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Capability" && selectedValue === "Execution efficiency") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 0 && item.subIndex === 0) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Capability" && selectedValue === "Quality of output") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 0 && item.subIndex === 1) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Capability" && selectedValue === "Accountability and Ownership") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 0 && item.subIndex === 2) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Creativity" && selectedValue === "ALL") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 1) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Creativity" && selectedValue === "Originality") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 1 && item.subIndex === 0) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Creativity" && selectedValue === "Alignment") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 1 && item.subIndex === 1) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Creativity" && selectedValue === "Complexity and Effort") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 1 && item.subIndex === 2) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Collaboration" && selectedValue === "ALL") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 2) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Collaboration" && selectedValue === "Team Contribution") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 2 && item.subIndex === 0) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Collaboration" && selectedValue === "Adaptability and Flexibility") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 2 && item.subIndex === 1) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Collaboration" && selectedValue === "Relationship Building") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 2 && item.subIndex === 2) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Compliance" && selectedValue === "ALL") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 3) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Compliance" && selectedValue === "Awareness") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 3 && item.subIndex === 0) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Compliance" && selectedValue === "Adherence") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 3 && item.subIndex === 1) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "Compliance" && selectedValue === "Accuracy and Integrity of records") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 3 && item.subIndex === 2) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "CustomerFocus" && selectedValue === "ALL") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 4) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "CustomerFocus" && selectedValue === "Convenience") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 4 && item.subIndex === 0) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "CustomerFocus" && selectedValue === "Value Creation") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 4 && item.subIndex === 1) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "CustomerFocus" && selectedValue === "Cost optimization") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 4 && item.subIndex === 2) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            if (selectionKey === "CustomerFocus" && selectedValue === "Brand promotion") {
                x.ratingsObj.forEach((item) => {
                    if (item.rowIndex === 4 && item.subIndex === 3) {
                        finalUKSCORE += item.UK;
                    }
                });
            }
            return { ...x, finalUKSCORE };
        });


        return finalDataToDisplay(updatedData);
    };


    function processNestedLists(nestedLists, selectionKey, selectedValue) {
        return nestedLists.map((list) => {
            let finalUKSCORE = 0;
            let date = list[0]?.rateStartTime?.split(" ")[0] || null; // Extract only the date part

            list.forEach((item) => {
                if (
                    (selectionKey === "Capability" && selectedValue === "ALL" && item.rowIndex === 0) ||
                    (selectionKey === "Capability" && selectedValue === "Execution efficiency" && item.rowIndex === 0 && item.subIndex === 0) ||
                    (selectionKey === "Capability" && selectedValue === "Quality of output" && item.rowIndex === 0 && item.subIndex === 1) ||
                    (selectionKey === "Capability" && selectedValue === "Accountability and Ownership" && item.rowIndex === 0 && item.subIndex === 2) ||
                    (selectionKey === "Creativity" && selectedValue === "ALL" && item.rowIndex === 1) ||
                    (selectionKey === "Creativity" && selectedValue === "Originality" && item.rowIndex === 1 && item.subIndex === 0) ||
                    (selectionKey === "Creativity" && selectedValue === "Alignment" && item.rowIndex === 1 && item.subIndex === 1) ||
                    (selectionKey === "Creativity" && selectedValue === "Complexity and Effort" && item.rowIndex === 1 && item.subIndex === 2) ||
                    (selectionKey === "Collaboration" && selectedValue === "ALL" && item.rowIndex === 2) ||
                    (selectionKey === "Collaboration" && selectedValue === "Team Contribution" && item.rowIndex === 2 && item.subIndex === 0) ||
                    (selectionKey === "Collaboration" && selectedValue === "Adaptability and Flexibility" && item.rowIndex === 2 && item.subIndex === 1) ||
                    (selectionKey === "Collaboration" && selectedValue === "Relationship Building" && item.rowIndex === 2 && item.subIndex === 2) ||
                    (selectionKey === "Compliance" && selectedValue === "ALL" && item.rowIndex === 3) ||
                    (selectionKey === "Compliance" && selectedValue === "Awareness" && item.rowIndex === 3 && item.subIndex === 0) ||
                    (selectionKey === "Compliance" && selectedValue === "Adherence" && item.rowIndex === 3 && item.subIndex === 1) ||
                    (selectionKey === "Compliance" && selectedValue === "Accuracy and Integrity of records" && item.rowIndex === 3 && item.subIndex === 2) ||
                    (selectionKey === "CustomerFocus" && selectedValue === "ALL" && item.rowIndex === 4) ||
                    (selectionKey === "CustomerFocus" && selectedValue === "Convenience" && item.rowIndex === 4 && item.subIndex === 0) ||
                    (selectionKey === "CustomerFocus" && selectedValue === "Value Creation" && item.rowIndex === 4 && item.subIndex === 1) ||
                    (selectionKey === "CustomerFocus" && selectedValue === "Cost optimization" && item.rowIndex === 4 && item.subIndex === 2) ||
                    (selectionKey === "CustomerFocus" && selectedValue === "Brand promotion" && item.rowIndex === 4 && item.subIndex === 3)
                ) {
                    finalUKSCORE += item.UK || 0;
                }
            });

            return { finalUKSCORE: Math.floor(finalUKSCORE), date };
        }).sort((a, b) => new Date(a.date) - new Date(b.date));
    }



    const finalDataToDisplay = (data) => {
        const processedData = data
            .map(obj => ({ ...obj, finalUKSCORE: Math.round(obj.finalUKSCORE) }))
            .sort((a, b) => b.finalUKSCORE - a.finalUKSCORE);
        const parsedData = processedData.map(obj => ({
            ...obj,
            lastSixRatings: processNestedLists(
                obj.lastSixRatings.map(rating => addUKKey(JSON.parse(rating))),
                selectionKey,
                selectedValue
            )
        }));
        console.log(parsedData, "lll")
        setSortedData(parsedData)
        setLoader(false)
    };



    useEffect(() => {
        if (perfData) {
            generateData();
        }
    }, [perfData]);

    const fetchRatings = async () => {
        try {
            setLoader(true);
            const res = await axiosInstance.put(`/user/getAllAssociateRatings?startDate=${startDate}&endDate=${endDate}`);
            const result = parseRatings(res.data);
            setPerfData(result);
        } catch (err) {
            console.error("API Error:", err);
        } finally {
            setLoader(false);
        }
    };



    useEffect(() => {
        if (startDate && endDate) {
            fetchRatings();
        }
    }, [startDate, endDate]);


    useEffect(() => {
        setLoader(true);
        generateData()
        setLoader(false);
    }, [selectionKey, selectedValue])


    return (
        <Card >
            <div style={{ margin: "20px", marginBottom: "0px" }}>
                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                    <div>
                        <Typography style={{
                            display: "inline-block",
                            marginRight: "5px"
                        }}>Category: </Typography>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            value={selectionKey}
                            onChange={handleKeyChange}
                            style={{ font: "small-caption", fontFamily: "Lucida Grande", height: "35px" }}
                        >
                            {Object.keys(data).map((key) => (
                                <MenuItem key={key} value={key} style={{ font: "small-caption" }}>
                                    {key}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div>
                        <Typography style={{
                            display: "inline-block",
                            marginRight: "5px"
                        }}>Parameters: </Typography>
                        <Select
                            id="value-select"
                            value={selectedValue}
                            onChange={handleValueChange}
                            style={{ font: "small-caption", fontFamily: "Lucida Grande", height: "35px" }}

                        >
                            <MenuItem value="ALL" style={{ font: "small-caption" }}>ALL</MenuItem>
                            {data[selectionKey]?.map((value, index) => (
                                <MenuItem key={index} value={value} style={{ font: "small-caption" }}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Tooltip title="Click">
                            <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
                                <ArrowBackIosIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>

                        <TextField
                            type="date"
                            value={startDate}
                            label="Start Date"
                            size="small"
                            onChange={handleStartDateChange}
                            InputLabelProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                                shrink: true,
                            }}
                            InputProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                            }}
                        />

                        <TextField
                            type="date"
                            label="End Date"
                            size="small"
                            value={endDate}
                            onChange={handleEndDateChange}
                            InputLabelProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                                shrink: true,
                            }}
                            InputProps={{
                                style: { font: "small-caption", fontFamily: "Lucida Grande" },
                            }}
                        />

                        <Tooltip title="Click">
                            <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
                                <ArrowForwardIosIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>

            {
                loader ? (
                    <Grid sx={{ textAlign: "center" }} item xs={12}>
                        <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                            <CircularProgress />
                        </Box>
                    </Grid>
                ) : sortedData.length === 0 ? (
                    <Grid sx={{ textAlign: "center" }} item xs={12}>
                        <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
                            <Typography>No data</Typography>
                        </Box>
                    </Grid>
                ) : (
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            textAlign: "center",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                            paddingLeft: "30px"
                        }}
                    >
                        {sortedData.slice(0, 5).map((data, index) => (
                            <Grid
                                item
                                xs={12} // Ensure full width on all screen sizes
                                sm={12} // Ensure full width on small screens
                                md={12} // Ensure full width on medium screens
                                key={index}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center", // Center content horizontally
                                    width: "100%", // Full width
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start", // Align the items to the top
                                        py: 3, // Decreased padding for reduced height
                                        px: 3,
                                        borderRadius: "12px",
                                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                                        backgroundColor: "#fff",
                                        mb: 3,
                                        width: "100%", // Ensures the Box takes full width
                                        maxWidth: "100%", // Removes max width constraint
                                        marginLeft: index === sortedData.slice(0, 5).length - 1 ? "20px" : "0", // Adds left margin to last card
                                        marginRight: "20px", // Adds right margin to all for spacing
                                        position: "relative", // Allows absolute positioning of left data
                                        height: "auto", // Adjust height to be flexible
                                    }}
                                >
                                    {/* Left Side Data */}
                                    <Box sx={{
                                        position: "absolute", // Position the text in the top-left corner
                                        top: "10px", // Align the text to the top of the card
                                        left: "10px", // Align the text to the left of the card
                                        textAlign: "left",
                                        maxWidth: "200px", // Optional: Set a max width for the left text
                                    }}>
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold", color: "#1F2937", fontSize: "20px" }}
                                        >
                                            {data.fullname || "Unknown Associate"}
                                        </Typography>
                                        <Typography sx={{
                                            fontWeight: "bold",
                                            color: "teal",
                                            marginBottom: "10px"
                                        }}>
                                            UK Score: {data.finalUKSCORE || 0}
                                        </Typography>
                                        <Typography sx={{ color: "#6B7280", fontSize: "14px" }}>
                                            Project: {data.project || "N/A"}
                                        </Typography>
                                        <Typography sx={{ color: "#6B7280", fontSize: "14px" }}>
                                            Manager: {data.projectManagerName || "N/A"}
                                        </Typography>
                                    </Box>

                                    {/* Right Side Table */}
                                    <Box sx={{ flex: 2, textAlign: "left", paddingLeft: "220px" }}> {/* Increase the flex value for the graph */}
                                        <PerformanceGraph performanceData={data.lastSixRatings} />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                )
            }

        </Card>
    );
};

export default PerfDashboard2;