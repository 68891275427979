import React, { useEffect, useRef } from 'react';
import { AgCharts } from 'ag-charts-community';

export default function PerformanceGraph({ performanceData }) {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!chartRef.current || !performanceData.length) return;

        const filteredData = performanceData
            .filter(item => item.date && item.finalUKSCORE > 0)
            .map(item => ({ date: item.date, score: item.finalUKSCORE }));

        if (filteredData.length === 0) return;

        const chartOptions = {
            container: chartRef.current,
            data: filteredData,
            series: [{
                type: 'line',
                xKey: 'date',
                yKey: 'score',
                stroke: '#3B82F6',
                marker: { size: 6, fill: '#3B82F6' },
                highlightStyle: { item: { fill: '#2563EB', strokeWidth: 2 } },
            }],
            axes: [
                { type: 'category', position: 'bottom' },
                { type: 'number', position: 'left' },
            ],
            tooltip: {
                enabled: true,
                renderer: ({ datum }) => ({
                    title: `Date: ${datum.date}`,
                    content: `Score: ${datum.score}`,
                }),
            },
        };

        const chart = AgCharts.create(chartOptions);

        return () => chart.destroy();
    }, [performanceData]);

    return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>;
}
