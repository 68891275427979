import styled from "@emotion/styled";
import { Input } from "@mui/base";
import {
  Add,
  AddOutlined,
  ManageAccounts,
  RemoveRedEye,
} from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "@mui/icons-material/Download";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useState } from "react";
import SearchFilter from "./SearchFilter";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../../components/edvenswa.emportal.auth/constants/Constants";
import RolePopover from "../../../components/edvenswa.emportal.console/user/RolePopover";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import YearDropdown from "../../edvenswa.emportal.holiday/pages/YearDropDown";

const GenericTable = (props) => {
  const {
    type,
    elements,
    filteredElements,
    setFilteredElements,
    directReporties,
    indirectReporties,
    setDirectReporties,
    setIndirectReporties,
    setSearchTerm,
    searchTerm,
    page,
    setPage,
    totalPages,
    isDrawerOpen,
    setIsDrawerOpen,
    toggleData,
    loading,
    sortBy,
    setSortBy,
    setUsers,
    setFilterReportee,
    setProjects,
    setHolidays,
    setStartDate,
    endDate,
    startDate,
    setEndDate,
    startDateError,
    endDateError,
    setStartDateError,
    setEndDateError,
    onError,
    onSuccess,
    appType,
    setAppType,
    Companies,
    setTenantId,
    state,
    setState,
  } = props;
  const user = JSON.parse(
    sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [tenant, setTenant] = useState(1);
  const [menuanchorEl, setMenuAnchorEl] = useState(null);
  const [roleAnchorEl, setRoleAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [pmsNotSubmitted, setPmsNotSubmitted] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const SORT_TYPES = ["ACTIVE", "INACTIVE", "ALL"];
  const SORT_TYPES1 = [
    { name: "Active", value: "ACTIVE" },
    { name: "Inactive", value: "INACTIVE" },
    { name: "All", value: "ALL" },
  ];

  const SORT_TASKS = ["ALL", "LEAVE", "TASKS"];

  const role = user.roles[0];
  const [isImported, setIsImpoprted] = useState(false);
  const popOverType = "";
  const projectDetails = [];
  const [errors, setErrors] = useState({});
  const projectsBasedonRole =
    role === "Reporting Manager"
      ? user.reportingManagerprojects.flatMap((projects) => projects.project)
      : user.projects;
  const projects =
    projectsBasedonRole.length > 1
      ? [{ title: "All" }, ...projectsBasedonRole]
      : projectsBasedonRole.length === 0
      ? [{ title: "No Projects Assigned" }]
      : projectsBasedonRole;
  const allProjects = projectsBasedonRole.map((project) => project.title);
  const [project, setProject] = useState(() => {
    if (projects[0]?.title === "All") {
      return allProjects;
    } else {
      return [projects[0]?.title];
    }
  });

  const handleStartDateChange = (e, type) => {
    if (type === "icon" && props.type === "Appraisals") {
      const newStartDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate)
              .subtract(1, "month")
              .startOf("month")
              .add(15, "days")
              .format("YYYY-MM-DD")
          : moment(startDate).startOf("month").format("YYYY-MM-DD");
      const newEndDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate)
              .subtract(1, "month")
              .endOf("month")
              .format("YYYY-MM-DD")
          : moment(startDate)
              .startOf("month")
              .add(14, "day")
              .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else if (type === "icon") {
      const newStartDate = moment(startDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const newEndDate = moment(endDate)
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      const value = e.target.value;
      setStartDate(value);
      setEndDateError("");
      if (value && endDate && value > endDate) {
        setStartDateError("Start date cannot be after end date");
      } else {
        setStartDateError("");
      }
    }
  };

  const handleEndDateChange = (e, type) => {
    if (type === "icon" && props.type === "Appraisals") {
      const newStartDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate).add(15, "days").format("YYYY-MM-DD")
          : moment(startDate)
              .add(1, "month")
              .startOf("month")
              .format("YYYY-MM-DD");
      const newEndDate =
        moment(startDate).format("DD").toString() === "01"
          ? moment(startDate).endOf("month").format("YYYY-MM-DD")
          : moment(startDate)
              .add(1, "month")
              .startOf("month")
              .add(14, "days")
              .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else if (type === "icon") {
      const newStartDate = moment(startDate)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const newEndDate = moment(endDate)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      const value = e.target.value;
      setEndDate(value);
      setStartDateError("");
      if (value && startDate && value < startDate) {
        setEndDateError("End date cannot be before start date");
      } else {
        setEndDateError("");
      }
    }
  };

  const handleDownloadMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDownloadClose = () => {
    setMenuAnchorEl(null);
  };

  const handleView = (event) => {
    event.preventDefault();
    toggleData(selectedItem);
    handleClose();
  };

  const handleDownloadPDF = () => {
    props.handleDownloadReport();
    handleDownloadClose();
  };

  const handleDownloadExcel = () => {
    props.handleDownloadReportExcel();
    handleDownloadClose();
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleFileUpload = (event, tenant) => {
    const selectedItem = event.target.files[0];
    const filename = selectedItem.name.split(".");
    const formData = new FormData();
    formData.append("file", selectedItem);

    if (selectedItem) {
      setIsImpoprted(true);
      axios
        .post(
          `${process.env.REACT_APP_EMPORTAL_SERVICE_BASE_URL}/user/excelUpload/${filename[0]}/${tenant}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          setIsImpoprted(false);
          const newElements = Array.isArray(response?.data)
            ? response?.data
            : [response?.data];
          setFilteredElements((prevElements) => [
            ...prevElements,
            ...newElements,
          ]);

          onSuccess("Excel file uploaded and data processed successfully.");
        })
        .catch((err) => {
          setIsImpoprted(false);
          onError(err);
        });
    }
  };

  const handleDownload = () => {
    axiosInstance
      .get("/user/demoExcel", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Emportal_ProtoData.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        // Handle error appropriately
      });
  };

  const openRolePopOver = (event) => {
    setRoleAnchorEl(event.currentTarget);
  };

  const handleRolePopOverClose = () => {
    setAnchorEl(null);
    setRoleAnchorEl(null);
  };
  const GenericTableHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #016466;
    font-size: 17px;
    font-weight: 540;
    font-family: "Lucida Grande";
    line-height: 1.25;
  `;

  const renderDateFields = () => {
    if (
      props.type === "User Report" ||
      props.type === "Tasks" ||
      props.type === "Report" ||
      props.type === "Appraisals" ||
      props.type === "User Ratings"
    ) {
      return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip title="click to go to previous month">
            <IconButton onClick={(e) => handleStartDateChange(e, "icon")}>
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <TextField
            type="date"
            value={startDate}
            label="Start Date"
            size="small"
            error={
              props.startDate && new Date(startDate) > new Date(endDate)
                ? "Start date cannot be after end date"
                : ""
            }
            helperText={
              startDate && new Date(startDate) > new Date(endDate)
                ? "Start date cannot be after end date"
                : ""
            }
            InputLabelProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
              shrink: true,
            }}
            InputProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
            }}
            onChange={handleStartDateChange}
          />
          &nbsp; &nbsp;
          <TextField
            type="date"
            value={endDate}
            label="End Date"
            size="small"
            error={
              startDate && new Date(endDate) < new Date(startDate)
                ? "End date cannot be before start date"
                : ""
            }
            helperText={
              startDate && new Date(endDate) < new Date(startDate)
                ? "End date cannot be before start date"
                : ""
            }
            InputLabelProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
              shrink: true,
            }}
            InputProps={{
              style: { font: "small-caption", fontFamily: "Lucida Grande" },
            }}
            onChange={handleEndDateChange}
          />
          <Tooltip title="click to go to next month">
            <IconButton onClick={(e) => handleEndDateChange(e, "icon")}>
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {props.handleDownloadReport ? (
            <Tooltip title={props.downloadTitle}>
              <IconButton
                onClick={handleDownloadMenuClick}
                disabled={
                  loading ||
                  Object.keys(errors).length !== 0 ||
                  startDateError ||
                  endDateError
                }
              >
                <DownloadIcon />
              </IconButton>
              <Menu
                anchorEl={menuanchorEl}
                open={Boolean(menuanchorEl)}
                onClose={handleDownloadClose}
              >
                <MenuItem
                  style={{ font: "small-caption" }}
                  onClick={() => handleDownloadPDF()}
                >
                  Download PDF
                </MenuItem>
                <MenuItem
                  style={{ font: "small-caption" }}
                  onClick={() => handleDownloadExcel()}
                >
                  Download Excel
                </MenuItem>
                  {/* {type === "Appraisals" && (
                    <MenuItem
                      style={{ font: "small-caption" }}
                      onClick={() => props?.handleDownloadPendingRatingsReport()}
                    >
                      Pending Ratings Report
                    </MenuItem>
                  )} */}
              </Menu>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
      );
    }
  };

  const DateFilter = () => {
    return (
      <TextField
        size="small"
        select
        defaultValue="Last Bi-Weekly"
        disabled={loading}
        InputProps={{
          style: {
            font: "small-caption",
            fontFamily: "Lucida Grande",
          },
        }}
        onChange={(e) => {
          props?.setDateFilter(e.target.value);
          let newStartDate = "";
          let newEndDate = "";
          switch (e.target.value) {
            case "Last Bi-Weekly":
              newStartDate =
                moment(new Date()).format("DD") > 15
                  ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
                  : moment(new Date())
                      .subtract(1, "month")
                      .startOf("month")
                      .add(15, "days")
                      .format("YYYY-MM-DD");
              newEndDate =
                moment(new Date()).format("DD") > 15
                  ? moment(new Date())
                      .startOf("month")
                      .add(14, "days")
                      .format("YYYY-MM-DD")
                  : moment(new Date())
                      .subtract(1, "month")
                      .endOf("month")
                      .format("YYYY-MM-DD");
              setStartDate(newStartDate);
              setEndDate(newEndDate);
              break;
            // case "Last 3 months":
            //   newStartDate = moment(new Date()).startOf('month').subtract(3, 'month').format('YYYY-MM-DD');
            //   newEndDate = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            //   setStartDate(newStartDate);
            //   setEndDate(newEndDate);
            //   break;
            case "Last 3 months":
              const selectedEndDate = moment(endDate, "YYYY-MM-DD");
              let adjustedStartDate;
              if (selectedEndDate.date() > 15) {
                adjustedStartDate = selectedEndDate
                  .clone()
                  .subtract(3, "months")
                  .startOf("month")
                  .add(1, "month");
              } else {
                adjustedStartDate = selectedEndDate
                  .clone()
                  .subtract(3, "months")
                  .date(16);
              }
              setStartDate(adjustedStartDate.format("YYYY-MM-DD"));
              setEndDate(selectedEndDate.format("YYYY-MM-DD"));
              break;

            case "Last 6 months":
              // newStartDate = moment(new Date()).startOf('month').subtract(6, 'month').format('YYYY-MM-DD');
              // newEndDate = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
              // setStartDate(newStartDate);
              // setEndDate(newEndDate);
              // break;
              const selectedEndDateFor6Months = moment(endDate, "YYYY-MM-DD");
              let adjustedStartDateFor6Months;
              if (selectedEndDateFor6Months.date() > 15) {
                adjustedStartDateFor6Months = selectedEndDateFor6Months
                  .clone()
                  .subtract(6, "months")
                  .startOf("month")
                  .add(1, "month");
              } else {
                adjustedStartDateFor6Months = selectedEndDateFor6Months
                  .clone()
                  .subtract(6, "months")
                  .date(16);
              }
              setStartDate(adjustedStartDateFor6Months.format("YYYY-MM-DD"));
              setEndDate(selectedEndDateFor6Months.format("YYYY-MM-DD"));
              break;
            case "Last 1 Year":
              // newStartDate = moment(new Date()).startOf('month').subtract(12, 'month').format('YYYY-MM-DD');
              // newEndDate = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
              // setStartDate(newStartDate);
              // setEndDate(newEndDate);
              // break;
              const selectedEndDateFor1Year = moment(endDate, "YYYY-MM-DD");
              let adjustedStartDateFor1Year;
              if (selectedEndDateFor1Year.date() > 15) {
                adjustedStartDateFor1Year = selectedEndDateFor1Year
                  .clone()
                  .subtract(12, "months")
                  .startOf("month")
                  .add(1, "month");
              } else {
                adjustedStartDateFor1Year = selectedEndDateFor1Year
                  .clone()
                  .subtract(12, "months")
                  .date(16);
              }
              setStartDate(adjustedStartDateFor1Year.format("YYYY-MM-DD"));
              setEndDate(selectedEndDateFor1Year.format("YYYY-MM-DD"));
              break;
          }
        }}
      >
        {[
          "Last Bi-Weekly",
          "Last 3 months",
          "Last 6 months",
          "Last 1 Year",
        ].map((val) => (
          <MenuItem
            style={{
              font: "small-caption",
              fontFamily: "Lucida Grande",
            }}
            key={val}
            value={val}
          >
            {val}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  return (
    <Card style={{ maxHeight: "479px" }}>
      <CardContent>
        <Grid container item xs={12}>
          <Grid item xs={4} sm={6}>
            <Stack direction={"row"} spacing={2} mb={1}>
              <GenericTableHeader>
                {type === "User Report" ? `${props.fullname} Report` : type}
              </GenericTableHeader>
              {type !== "Tasks" ? (
                <>
                  {type !== "Companies" &&
                  type !== "User Ratings" &&
                  role !== "Project Manager" &&
                  role !== "Reporting Manager" &&
                  type !== "User Report" ? (
                    <>
                      <TextField
                        size="small"
                        select
                        disabled={loading}
                        defaultValue={user.tenant}
                        InputProps={{
                          style: {
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          },
                        }}
                        onChange={(e) => {
                          const company = Companies.find(
                            (data) => data.name === e.target.value
                          );
                          setTenantId(company.id);
                          setTenant(company.id);
                          setSearchTerm("");
                          if (type === "Projects") {
                            setProjects([]);
                          }
                          if (type === "Associates" || type === "Report") {
                            setUsers([]);
                          }
                          if (type === "Holidays") {
                            setHolidays([]);
                          }
                          if (type !== "Report") {
                            setSortBy("ACTIVE");
                          }
                        }}
                      >
                        {Companies &&
                          Companies?.length > 0 &&
                          Companies.map((val) => (
                            <MenuItem
                              style={{
                                font: "small-caption",
                                fontFamily: "Lucida Grande",
                              }}
                              key={val}
                              value={val.name}
                            >
                              {val.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      {type === "Holidays" && (
                        <YearDropdown
                          setSelectedYear={props.setSelectedYear}
                          selectedYear={props?.selectedYear}
                        />
                      )}
                    </>
                  ) : (role === "Project Manager" ||
                      role === "Reporting Manager") &&
                    (type !== "Appraisals" || type !== "User Ratings") ? (
                    <TextField
                      size="small"
                      select
                      disabled={loading || type === "User Report"}
                      defaultValue={
                        type === "User Report"
                          ? props?.project
                          : projects[0]?.title
                      }
                      InputProps={{
                        style: {
                          font: "small-caption",
                          fontFamily: "Lucida Grande",
                        },
                      }}
                      onChange={(e) => {
                        const selectedProject = projects.find(
                          (data) => data.title === e.target.value
                        );
                        setProject(selectedProject.title);
                        if (type === "Appraisals") {
                          props.setProjectFromAppraisal(selectedProject.title);
                        }
                        if (type === "Projects") {
                          setProjects([]);
                        }
                        if (type === "Associates") {
                          props.setProjectForUsers(selectedProject.title);
                        }

                        if (type === "Associates" || type === "Report") {
                          if (type === "Report") {
                            props?.setProject(selectedProject.title);
                          }
                          setUsers([]);

                          if (selectedProject.title === "All") {
                            // Display all users across all projects
                            let allUsers = [];
                            user.projects.forEach((project) => {
                              project.users.forEach((associate) => {
                                let associateObj = {
                                  ...associate,
                                  projectShow: project.title,
                                };
                                allUsers.push(associateObj);
                              });
                            });
                            allUsers = allUsers.filter(
                              (user, index, self) =>
                                index ===
                                self.findIndex(
                                  (u) => u.associateId === user.associateId
                                )
                            );

                            setFilteredProjects(allUsers);
                            setFilteredElements(
                              allUsers.filter(
                                (data) => data.associateId !== user.associateId
                              )
                            );
                          } else {
                            // Display users of the selected project
                            let projectUsers = selectedProject.users.map(
                              (associate) => ({
                                ...associate,
                                projectShow: selectedProject.title,
                              })
                            );
                            setFilteredProjects(projectUsers);
                            setFilteredElements(
                              projectUsers.filter(
                                (data) => data.associateId !== user.associateId
                              )
                            );
                          }
                        }

                        if (type === "Holidays") {
                          setHolidays([]);
                        }

                        if (type !== "Report" && type !== "Appraisals" && type !== "User Ratings") {
                          setSortBy("ACTIVE");
                        }
                      }}
                    >
                      {projects.map((val) => (
                        <MenuItem
                          style={{
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          }}
                          key={val.title}
                          value={val.title}
                        >
                          {val.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <></>
                  )}
                  <Divider />
                  {role === "Project Manager" && type === "Associates" && (
                    <TextField
                      size="small"
                      select
                      defaultValue={"All"}
                      disabled={loading}
                      InputProps={{
                        style: {
                          font: "small-caption",
                          fontFamily: "Lucida Grande",
                        },
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFilterReportee(value);
                        if (value === "All" && filteredProjects.length > 0) {
                          setFilteredElements(filteredProjects);
                          setUsers(filteredProjects);
                        } else if (
                          value === "All" &&
                          filteredProjects.length === 0
                        ) {
                          setFilteredElements(elements);
                          setUsers(elements);
                        } else if (
                          value === "Direct Reportees" &&
                          filteredProjects.length > 0
                        ) {
                          setDirectReporties(
                            filteredProjects.filter(
                              (data) => data.role === "Reporting Manager"
                            )
                          );
                        } else if (
                          value === "Direct Reportees" &&
                          filteredProjects.length === 0
                        ) {
                          setDirectReporties(
                            elements.filter(
                              (data) => data.role === "Reporting Manager"
                            )
                          );
                        } else if (
                          value === "Indirect Reportees" &&
                          filteredProjects.length > 0
                        ) {
                          setIndirectReporties(
                            filteredProjects.filter(
                              (data) =>
                                data.role !== "Reporting Manager" &&
                                data.associateId !== user.associateId
                            )
                          );
                        } else if (
                          value === "Indirect Reportees" &&
                          filteredProjects.length === 0
                        ) {
                          setIndirectReporties(
                            elements.filter(
                              (data) =>
                                data.role !== "Reporting Manager" &&
                                data.associateId !== user.associateId
                            )
                          );
                        }
                      }}
                    >
                      {["All", "Direct Reportees", "Indirect Reportees"].map(
                        (val) => (
                          <MenuItem
                            style={{
                              font: "small-caption",
                              fontFamily: "Lucida Grande",
                            }}
                            key={val}
                            value={val}
                          >
                            {val}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  )}
                  <Divider />
                  {type === "Appraisals" && role === "Admin" ? (
                    <>
                      <TextField
                        size="small"
                        select
                        value={appType}
                        disabled={loading}
                        InputProps={{
                          style: {
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          },
                        }}
                        onChange={(e) => {
                          setAppType(e.target.value);
                        }}
                      >
                        {["Users", "Project Managers"].map((val) => (
                          <MenuItem
                            style={{
                              font: "small-caption",
                              fontFamily: "Lucida Grande",
                            }}
                            key={val}
                            value={val}
                          >
                            {val}
                          </MenuItem>
                        ))}
                      </TextField>
                      {DateFilter()}
                    </>
                  ) : type === "Appraisals" && role === "Project Manager" ? (
                    DateFilter()
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <React.Fragment />
              )}
              {type !== "Holidays" &&
              type !== "Report" &&
              type !== "Tasks" &&
              type !== "User Ratings" &&
              role !== "Reporting Manager" &&
              type !== "Appraisals" &&
              role !== "Project Manager" ? (
                <>
                  {type !== "User Report" ? (
                    <TextField
                      size="small"
                      select
                      value={sortBy}
                      disabled={loading}
                      InputProps={{
                        style: {
                          font: "small-caption",
                          fontFamily: "Lucida Grande",
                        },
                      }}
                      onChange={(e) => {
                        setSortBy(e.target.value);
                        if (type === "Projects") {
                          setProjects([]);
                        }
                        if (type === "Associates") {
                          setUsers([]);
                        }
                      }}
                    >
                      {SORT_TYPES1 &&
                        SORT_TYPES1?.length > 0 &&
                        SORT_TYPES1.map((val) => (
                          <MenuItem
                            style={{
                              font: "small-caption",
                              fontFamily: "Lucida Grande",
                            }}
                            key={val}
                            value={val.value}
                          >
                            {val.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  ) : (
                    <></>
                  )}
                  <Divider />
                </>
              ) : (
                <React.Fragment />
              )}
            </Stack>
          </Grid>
          <Grid item xs={4} sm={6}>
            <Stack
              direction={"row"}
              spacing={2}
              mb={1}
              // direction="row"
              // justifyContent={"end"}
              // mb={1}
              // sx={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
            >
              {props.type === "User Report" || props.type === "User Ratings" ? (
                <>{renderDateFields()}</>
              ) : props.type === "Tasks" ||
                props.type === "Report" ||
                props.type === "Appraisals" ? (
                <>
                  <>{renderDateFields()}</>
                  &nbsp;
                  <SearchFilter
                    setPage={setPage}
                    elements={elements}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    setFilteredElements={setFilteredElements}
                    state={state}
                    setState={setState}
                  />
                  &nbsp;
                </>
              ) : (
                <>
                  &nbsp;
                  <SearchFilter
                    setPage={setPage}
                    elements={elements}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    setFilteredElements={setFilteredElements}
                    state={state}
                    setState={setState}
                  />
                  &nbsp;
                </>
              )}

              {props.displayAdd &&
              !(
                type === "Associates" &&
                (role === "Project Manager" || role === "Reporting Manager")
              ) ? (
                <Fragment>
                  <Divider orientation="vertical" flexItem />
                  {props.handleDownloadReport ? (
                    <Tooltip title={props.downloadTitle}>
                      <IconButton onClick={handleDownloadMenuClick}>
                        <DownloadIcon />
                      </IconButton>
                      <Menu
                        anchorEl={menuanchorEl}
                        open={Boolean(menuanchorEl)}
                        onClose={handleDownloadClose}
                      >
                        <MenuItem
                          style={{
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          }}
                          onClick={() => handleDownloadPDF()}
                        >
                          Download PDF
                        </MenuItem>
                        <MenuItem
                          style={{
                            font: "small-caption",
                            fontFamily: "Lucida Grande",
                          }}
                          onClick={() => handleDownloadExcel()}
                        >
                          Download Excel
                        </MenuItem>
                      </Menu>
                    </Tooltip>
                  ) : (
                    <></>
                  )}

                  {type === "Associates" &&
                  role !== "Project Manager" &&
                  role !== "Reporting Manager" ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          size="small"
                          icon={<Add style={{ color: "white" }} />}
                          disabled={loading}
                          label={
                            <Typography
                              variant="body2"
                              style={{ fontSize: "0.8rem", color: "white" }}
                            >
                              {type === "Report"
                                ? "Download " + type
                                : "Add " + type}
                            </Typography>
                          }
                          onClick={() => {
                            setIsDrawerOpen(true);
                          }}
                          sx={{
                            marginLeft: 1,
                            fontSize: 17,
                            color: "white",
                            backgroundColor: "#029a9c",
                          }}
                        >
                          <AddOutlined />
                          {type === "Report" || type === "User Report" ? (
                            <>GENERATE {type}</>
                          ) : (
                            <>ADD {type}</>
                          )}
                        </Chip>
                      </Grid>
                      <form encType="multipart/form-data">
                        <InputLabel htmlFor="fileInput">
                          <Input
                            type="file"
                            name="file"
                            id="fileInput"
                            accept=".xlsx"
                            style={{ display: "none" }}
                            onChange={(event) => {
                              handleFileUpload(event, tenant);
                            }}
                          />
                          {isImported ? (
                            <CircularProgress />
                          ) : (
                            <Chip
                              size="small"
                              icon={<Add style={{ color: "white" }} />}
                              disabled={loading}
                              label={
                                <Tooltip title="Looking for Excel Demo File Click on Excel ProtoData">
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontSize: "0.8rem",
                                      color: "white",
                                    }}
                                  >
                                    Import Associates
                                  </Typography>
                                </Tooltip>
                              }
                              onClick={() => {
                                setIsDrawerOpen(false);
                              }}
                              sx={{
                                fontSize: 17,
                                color: "white",
                                backgroundColor: "#029a9c",
                                marginRight: 1,
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </InputLabel>
                      </form>
                      <Chip
                        size="small"
                        disabled={loading}
                        label={
                          <Tooltip title="Click here to download Excel Demo Data file">
                            <Typography
                              variant="body2"
                              style={{ fontSize: "0.8rem", color: "white" }}
                            >
                              Excel ProtoData
                            </Typography>
                          </Tooltip>
                        }
                        onClick={() => {
                          handleDownload();
                        }}
                        sx={{
                          fontSize: 17,
                          color: "white",
                          backgroundColor: "#029a9c",
                        }}
                      />
                    </Box>
                  ) : (
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Chip
                        size="small"
                        icon={<Add style={{ color: "white" }} />}
                        disabled={loading}
                        label={
                          <Typography
                            variant="body2"
                            style={{ fontSize: "0.8rem", color: "white" }}
                          >
                            {type === "Report"
                              ? "Download " + type
                              : "Add " + type}
                          </Typography>
                        }
                        onClick={() => {
                          setIsDrawerOpen(true);
                        }}
                        sx={{
                          marginLeft: 1,
                          fontSize: 17,
                          color: "white",
                          backgroundColor: "#029a9c",
                        }}
                      >
                        <AddOutlined />
                        {type === "Report" || type === "User Report" ? (
                          <>GENERATE {type}</>
                        ) : (
                          <>ADD {type}</>
                        )}
                      </Chip>
                    </Grid>
                  )}
                </Fragment>
              ) : (
                <Fragment />
              )}
            </Stack>
          </Grid>
        </Grid>

        {loading ? (
          <Grid sx={{ textAlignLast: "center" }} item xs={12}>
            <Box style={{ paddingTop: "50px", paddingBottom: "40px" }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ backgroundColor: "#FFFFFF" }}>
                {filteredElements.length !== 0 ? (
                  <div
                    className="ag-theme-alpine" // applying the grid theme
                    style={{
                      height: 419,
                      width: "100%",
                      fontFamily: "Lucida Grande",
                    }}
                  >
                    <AgGridReact
                      rowData={filteredElements}
                      columnDefs={props.colDefs}
                      domLayout="normal" // Ensures grid adapts to container
                      defaultColDef={{
                        filter: true,
                        headerClass: "custom-header",
                      }}
                      onGridReady={(params) => params.api.sizeColumnsToFit()}
                    />
                  </div>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      component="h6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <WarningAmberIcon fontSize="small" />
                      &nbsp; {`No ${type.toLowerCase()}.`}
                    </Typography>
                  </Grid>
                )}
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {props.displayPagination ? (
                totalPages > 0 && (
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                  />
                )
              ) : (
                <></>
              )}
            </Grid>

            {
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {popOverType === "ACTION" ? (
                  <Grid>
                    <MenuItem onClick={handleView}>
                      <ListItemIcon>
                        <RemoveRedEye />
                      </ListItemIcon>
                      <ListItemText> View</ListItemText>
                    </MenuItem>
                    {type === "Associates" ? (
                      <MenuItem onClick={(event) => openRolePopOver(event)}>
                        <ListItemIcon>
                          <ManageAccounts />
                        </ListItemIcon>
                        <ListItemText> Edit Role</ListItemText>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </Grid>
                ) : popOverType === "SORT" ? (
                  SORT_TYPES &&
                  SORT_TYPES?.length > 0 &&
                  SORT_TYPES.map((item, key) => (
                    <MenuItem
                      key={key}
                      onClick={() => {
                        setSortBy(item);
                        setAnchorEl(null);
                      }}
                    >
                      <ListItemText>{item}</ListItemText>
                    </MenuItem>
                  ))
                ) : popOverType === "PROJECT DETAILS" ? (
                  <Card sx={{ width: 350 }}>
                    <CardHeader
                      title={"Project Details"}
                      sx={{
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "#d5ecec",
                      }}
                    />
                    <CardContent>
                      <Grid container>
                        <Table>
                          <TableHead>
                            <TableCell>
                              <Typography
                                variant="h7"
                                style={{ fontWeight: "bold" }}
                              >
                                Project Name
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="h7"
                                style={{ fontWeight: "bold" }}
                              >
                                Project Manager
                              </Typography>
                            </TableCell>
                          </TableHead>
                          {projectDetails && projectDetails.length > 0 ? (
                            projectDetails &&
                            projectDetails?.length > 0 &&
                            projectDetails.map((project) => (
                              <TableBody>
                                <TableRow key={project.title}>
                                  <TableCell>{project.title}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {project.projectManager}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={2} align="center">
                                <Typography>No Data Found</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </Table>
                      </Grid>
                    </CardContent>
                  </Card>
                ) : (
                  SORT_TASKS &&
                  SORT_TASKS?.length > 0 &&
                  SORT_TASKS.map((item, key) => (
                    <MenuItem
                      style={{ font: "small-caption" }}
                      key={key}
                      onClick={() => {
                        setSortBy(item);
                        setAnchorEl(null);
                      }}
                    >
                      <ListItemText>{item}</ListItemText>
                    </MenuItem>
                  ))
                )}
              </Popover>
            }
            {roleAnchorEl ? (
              <RolePopover
                onError={onError}
                onSuccess={onSuccess}
                el={roleAnchorEl}
                handleClose={handleRolePopOverClose}
                user={selectedItem}
                setUser={setSelectedItem}
              />
            ) : (
              <></>
            )}
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              PaperProps={{ sx: { width: "33%" } }}
            >
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ color: "#01164D" }}>
                    {" "}
                    {type === "Report" ? `Generate ${type}` : `Create ${type}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {props.drawerContent}
                </Grid>
              </Grid>
            </Drawer>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
};

export default GenericTable;
