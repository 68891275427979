import moment from "moment";
 
export const TASK_ID_FIELD_ID = 'taskId';
export const TASK_TITLE_FIELD_ID = 'taskName';
export const TASK_DESCRIPTION_FIELD_ID = 'description';
export const TASK_CATEGORY_FIELD_ID = 'category';
export const TASK_PROJECTS_FIELD_ID = 'project';
export const TASK_START_DATE_FIELD_ID = 'startDate';
export const TASK_END_DATE_FIELD_ID = 'endDate';
export const TASK_ESTIMATEDHOURS_FIELD_ID = 'estimatedHours';
export const TIMESHEET_TASKS_FIELD_ID = 'taskId';
export const START_DATE_FORMAT = moment(new Date()).startOf("month").format("YYYY-MM-DD");
export const END_DATE_FORMAT = moment(new Date()).endOf("month").format("YYYY-MM-DD");
export const DATE_FORMAT = moment(new Date()).format("YYYY-MM-DD");
export const TASK_CATEGORY_MAPPING = "/task/category";
export const DIALOG_TYPE = "Task Category";
export const DIALOG_FIELD_NAME = "category";
export const TIMECARDS_PATH = "/home/timecard";
export const TIMESHEET_PATH = "/home/timecard/timesheet";
export const TASK_ASSIGNEE_FIELD_ID = 'assignedTo';
