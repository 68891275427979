import {
  Box,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";
import QRCodeGenerator from "./QRCodeGenerator";

const FeedbackDashboard = () => {
  const moment = require("moment");
  const user = JSON.parse(
    sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)
  );
  const [feedbacks, setFeedbacks] = useState([]);
  const [filterFeedbacks, setFilterFeedbacks] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("recent");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/user/client-feedbacks?astId=${user?.associateId}`)
      .then((response) => {
        setFeedbacks(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!feedbacks.length) return;

    let sortedFeedbacks = [...feedbacks]; // Create a new array to prevent mutating state

    switch (selectedFilter) {
      case "recent":
        sortedFeedbacks
          .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))
          .reverse();
        break;
      case "highest":
        sortedFeedbacks.sort((a, b) => b.rating - a.rating);
        break;
      case "lowest":
        sortedFeedbacks.sort((a, b) => a.rating - b.rating);
        break;
      default:
        break;
    }

    setFilterFeedbacks(sortedFeedbacks);
  }, [selectedFilter, feedbacks]); // Add `feedbacks` as a dependency

  const generateQRCodePlaceholder = () => <QRCodeGenerator user={user} />;

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100vh",
        p: 4,
      }}
    >
      <Grid container spacing={3}>
        {/* Sidebar Insights */}
        <Grid item xs={12} md={3}>
          <Stack spacing={1}>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6" color="primary">
                      Feedback Insights
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Total Feedbacks</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {feedbacks.length}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Average Rating</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {feedbacks.length > 0
                        ? Math.floor(
                            (feedbacks.reduce(
                              (acc, curr) => acc + curr.rating,
                              0
                            ) /
                              feedbacks.length) *
                              100
                          ) / 100
                        : 0}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>

            {generateQRCodePlaceholder()}
          </Stack>
        </Grid>

        {/* Feedback List */}
        <Grid item xs={12} md={9}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h4">Feedback Dashboard</Typography>

            <Box display="flex" alignItems="center">
              <FormControl
                variant="outlined"
                size="small"
                sx={{ mr: 2, minWidth: 200 }}
              >
                <InputLabel>Filter Feedback</InputLabel>
                <Select
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  label="Filter Feedback"
                >
                  <MenuItem value="recent">Most Recent</MenuItem>
                  <MenuItem value="highest">Highest Rated</MenuItem>
                  <MenuItem value="lowest">Lowest Rated</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          <Stack spacing={2}>
            {loading ? (
              <Typography variant="body1" color="textSecondary" align="center">
                Loading feedbacks...
              </Typography>
            ) : filterFeedbacks.length === 0 ? ( // Ensure this check happens only after loading is false
              <Typography variant="body1" color="textSecondary" align="center">
                No feedbacks available.
              </Typography>
            ) : (
              filterFeedbacks.map((feedback) => (
                <Card key={feedback.id} variant="outlined">
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Box>
                            <Typography variant="h6">
                              {feedback.clientName.charAt(0).toUpperCase() +
                                feedback.clientName.slice(1)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {feedback.clientEmail}
                            </Typography>
                          </Box>

                          <Rating
                            value={feedback.rating}
                            readOnly
                            precision={0.5}
                          />
                        </Box>

                        <Typography variant="body1" sx={{ my: 2 }}>
                          {feedback.feedback}
                        </Typography>
                      </Grid>

                      <Grid item xs={2} textAlign="right">
                        <Typography variant="caption" color="text.secondary">
                          {moment([
                            feedback.dateTime[0],
                            feedback.dateTime[1] - 1,
                            feedback.dateTime[2],
                            feedback.dateTime[3],
                            feedback.dateTime[4],
                          ]).format("YYYY-MM-DD h:mm A")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeedbackDashboard;
