import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Box,
  IconButton,
  Container,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
} from "@mui/material";
import {
  ThumbUp,
  ThumbDown,
  Favorite,
  PersonOutline,
} from "@mui/icons-material";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { FEEDBACK } from "../../edvenswa.emportal.sidebar/constants/constants";

const FeatureReviews = (props) => {
  const [selectedFeature, setSelectedFeature] = useState("all");
  const [selectedReaction, setSelectedReaction] = useState("all");
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    props?.setType(FEEDBACK);

    axiosInstance
      .get("/feedback/reviews")
      .then((res) => setReviews(transformFeedbackData(res?.data)))
      .catch((err) => console.log(err));
  }, [selectedFeature]);

  const transformFeedbackData = (data) => {
    return Object.entries(data).map(([feature, reviews]) => ({
      feature,
      reviews: reviews.map(
        ({
          id,
          userName,
          date,
          reaction,
          comment,
          avatar,
          anonymous,
          level,
          version,
          role,
        }) => ({
          id,
          userName,
          date: new Date(...date).toISOString(),
          reaction: reaction.toLowerCase(),
          comment,
          avatar,
          anonymous,
          level,
          version,
          role,
        })
      ),
    }));
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const ReactionIcon = ({ reaction }) => {
    switch (reaction) {
      case "like":
        return <ThumbUp sx={{ color: "teal" }} />;
      case "dislike":
        return <ThumbDown sx={{ color: "red" }} />;
      case "loved it":
        return <Favorite sx={{ color: "purple" }} />;
      default:
        return null;
    }
  };

  const getFilteredReviews = () => {
    let filteredGroups = [...reviews];

    if (selectedFeature !== "all") {
      filteredGroups = filteredGroups.filter(
        (group) => group.feature === selectedFeature
      );
    }

    if (selectedReaction !== "all") {
      filteredGroups = filteredGroups
        .map((group) => ({
          ...group,
          reviews: group.reviews.filter(
            (review) => review.reaction === selectedReaction
          ),
        }))
        .filter((group) => group.reviews.length > 0);
    }

    return filteredGroups;
  };

  const handleFeatureChange = (event) => {
    setSelectedFeature(event.target.value);
  };

  const handleReactionChange = (_, newReaction) => {
    setSelectedReaction(newReaction || "all");
  };

  const getReactionCounts = () => {
    const counts = { like: 0, dislike: 0, loved: 0 };

    if (selectedFeature !== "all") {
      const rws = reviews.filter((r) => r.feature === selectedFeature);
      rws.forEach((group) => {
        group.reviews.forEach((review) => {
          if (review.reaction === "loved it") {
            counts.loved++;
          } else counts[review.reaction]++;
        });
      });
      return counts;
    } else {
      reviews.forEach((group) => {
        group.reviews.forEach((review) => {
          if (review.reaction === "loved it") {
            counts.loved++;
          } else counts[review.reaction]++;
        });
      });
      return counts;
    }
  };

  const reactionCounts = getReactionCounts();

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 3 }}
      >
        <ToggleButtonGroup
          value={selectedReaction}
          exclusive
          onChange={handleReactionChange}
          aria-label="reaction filter"
          size="small"
          sx={{
            "& .MuiToggleButton-root": {
              px: 1.5,
              py: 0.5,
              fontSize: "0.75rem",
            },
          }}
        >
          <ToggleButton value="all" aria-label="all reactions">
            <Typography variant="caption" sx={{ mr: 0.5 }}>
              All
            </Typography>
          </ToggleButton>
          <ToggleButton value="like" aria-label="liked">
            <ThumbUp sx={{ color: "teal", mr: 0.5, fontSize: 18 }} />
            <Typography variant="caption">{reactionCounts.like}</Typography>
          </ToggleButton>
          <ToggleButton value="dislike" aria-label="disliked">
            <ThumbDown sx={{ color: "red", mr: 0.5, fontSize: 18 }} />
            <Typography variant="caption">{reactionCounts.dislike}</Typography>
          </ToggleButton>
          <ToggleButton value="loved it" aria-label="loved">
            <Favorite sx={{ color: "purple", mr: 0.5, fontSize: 18 }} />
            <Typography variant="caption">{reactionCounts.loved}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>

        <FormControl sx={{ minWidth: 120 }}>
          <Select
            value={selectedFeature}
            onChange={handleFeatureChange}
            displayEmpty
            size="small"
            sx={{
              ".MuiSelect-select": {
                py: 0.5,
                fontSize: "0.875rem",
              },
            }}
          >
            <MenuItem value="all">All Features</MenuItem>
            {reviews
              .slice()
              .sort((a, b) => a.feature.localeCompare(b.feature))
              .map((group) => (
                <MenuItem
                  key={group.feature}
                  value={group.feature}
                  sx={{ fontSize: "0.875rem" }}
                >
                  {group.feature}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>

      {getFilteredReviews().map((featureGroup) => (
        <Card key={featureGroup.feature} sx={{ mb: 1, boxShadow: 2 }}>
          <CardHeader
            sx={{ p: "6px" }}
            title={featureGroup.feature}
            action={
              <Chip
                label={`${featureGroup.reviews.length} reviews`}
                color="primary"
                variant="outlined"
                size="small"
              />
            }
          />
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {featureGroup.reviews.map((review, index) => (
                <React.Fragment key={review.id}>
                  {index > 0 && <Divider sx={{ my: 2 }} />}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Avatar sx={{ bgcolor: "primary.main" }}>
                      {review.anonymous ? <PersonOutline /> : review.avatar}
                    </Avatar>
                    <Box sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {review?.anonymous ? (
                            <>
                              <Typography variant="subtitle1" component="span">
                                {review?.role === "Admin"
                                  ? "Admin"
                                  : review.level}
                              </Typography>
                              <IconButton sx={{ p: 0, ml: 1 }}>
                                <ReactionIcon
                                  reaction={review.reaction}
                                  sx={{ fontSize: 12 }}
                                />
                              </IconButton>
                            </>
                          ) : (
                            <Typography>
                              <Typography variant="subtitle1" component="span">
                                {review?.userName}
                              </Typography>{" "}
                              <IconButton sx={{ p: 0, ml: 1 }}>
                                <ReactionIcon
                                  reaction={review?.reaction}
                                  sx={{ fontSize: 12 }}
                                />
                              </IconButton>
                              <br />
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                component="span"
                              >
                                {review?.role === "Admin"
                                  ? "Admin"
                                  : review.level}
                              </Typography>
                            </Typography>
                          )}
                        </Box>
                        <Typography variant="caption" color="text.secondary">
                          {formatDate(review?.date)}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1">
                        {review?.comment}
                      </Typography>
                    </Box>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
};

export default FeatureReviews;
