import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";

const ProfileCardComponent = React.lazy(() => import("../profile/ProfileCard"));
const ChangePasswordComponent = React.lazy(() =>
  import("../profile/ChangePassword")
);
export const PROFILE_ROUTES = [
  <Route
    path="/home/profile"
    element={
      <PrivateRoute>
        <Layout
          component={ProfileCardComponent}
          path="/home/profile"
          title="Profile"
        ></Layout>
      </PrivateRoute>
    }
    key={"PROFILE_ROUTES"}
  ></Route>,
  <Route
    path="/home/changepassword"
    element={
      <PrivateRoute>
        <Layout
          component={ChangePasswordComponent}
          path="/home/changepassword"
          title="Rest Password"
        ></Layout>
      </PrivateRoute>
    }
    key={"CHANGE_PASSWORD_ROUTES"}
  ></Route>,
];
